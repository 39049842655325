<template>
  <main class="container-fluid">
    <b-card class="af-card pb-5">
      <b-tabs content-class="mt-3">
        <b-tab title="Suspended Users" title-link-class="px-0 mr-3" active>
          <SuspendedUsers :users="users" />
        </b-tab>
        <b-tab title="Deleted Users" title-link-class="px-0 mr-3">
          <DeletedUsers :users="users" />
        </b-tab>
      </b-tabs>
    </b-card>
  </main>
</template>

<script>
import SuspendedUsers from '../components/suspension/SuspendedUsers.vue'
import DeletedUsers from '../components/suspension/DeletedUsers.vue'

export default {
  metaInfo: {
    title: 'Suspension',
    titleTemplate: '%s | AirtimeFlip'
  },
  components: {
    SuspendedUsers,
    DeletedUsers
  },
  data () {
    return {
      users: []
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
