<template>
  <div>
    <b-row class="justify-content-between">
      <b-col cols="6" class="mt-4">
        <b-form-group class="af-input">
          <b-input-group class="mb-3">
            <b-form-input v-model="filter" class="af-input-field" required></b-form-input>
            <template v-slot:append>
              <b-input-group-text class="af-input-append">
                <span class="icon-magnifier"></span>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="3" class="mt-4">
        <b-form-select v-model="selected" :options="options" class="mr-3"></b-form-select>
      </b-col> -->
    </b-row>
    <!-- table -->
    <b-table
      id="af-table"
      :items="getUsers"
      :fields="fields"
      :filter="filter"
      :currentPage="currentPage"
      :perPage="perPage"
      hover
      responsive
      borderless
      show-empty
    >
      <template #empty>
        <p class="text-center text-muted">No data found</p>
      </template>

      <template v-slot:head(name)>
        <span class="text-uppercase font-weight-bold">User</span>
      </template>

      <template v-slot:head(verified)>
        <span class="text-uppercase font-weight-bold">Reason</span>
      </template>

      <template v-slot:head(deleted)>
        <span class="text-uppercase font-weight-bold">Status</span>
      </template>

      <template v-slot:cell(name)="data">
        <div class="row align-items-center no-gutters">
          <div class="text-uppercase mr-2">
            <span class="initials">{{ computeInitials(data.value) }}</span>
          </div>
          <div>
            <p class="mb-0">{{ data.value }}</p>
            <p class="text-muted text-14 mb-0">USER</p>
          </div>
        </div>
      </template>

      <template v-slot:cell(deleted)>
        <span class="text-danger font-weight-bold text-uppercase">Deleted</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      align="right"
      hide-ellipsis
      hide-goto-end-buttons
      aria-controls="af-table"
    >
      <template v-slot:page="{ page, active }">
        <span class="active" v-if="active">{{ page }}</span>
        <span v-else>{{ page }}</span>
      </template>
    </b-pagination>
  </div>
</template>

<script>
import { getUsers } from '@/apis/users'

export default {
  props: {
    users: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      currentPage: 1,
      perPage: 25,
      aggregate: 25,
      filter: null,
      filteredUsers: [],
      fields: [
        'name',
        'verified',
        'deleted'
      ]
    }
  },
  computed: {
    rows () {
      return this.aggregate
    }
  },
  methods: {
    computeInitials (name) {
      const names = name.split(' ')
      let initials = ''
      for (let i = 0; i < names.length; i++) {
        initials += names[i].charAt(0)
      }
      return initials
    },
    async getUsers () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      const query = `page=${this.currentPage}`
      try {
        const { data } = await getUsers(query)
        this.filteredUsers = data.data.filter(item => item.deleted)
        this.aggregate = data.agreegate
        return this.filteredUsers
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.initials {
  background-color: #3FBBC2;
  color: white;
  padding: 13px;
  border-radius: 50%;
}
.page-item.active .page-link {
  background-color: #F38D04;
  color: #fff;
}
</style>
<style>
.hidden_header {
  text-transform: uppercase;
}
</style>
